.big-screen {
    width: 600px;
    height: 100px;
    background: #7c52bf;
    margin: 0 auto;  
}

.big-screen p {
    padding-top: 2%;
}

.Banner-background {
    position: absolute;
    width: 1440px;
    height: 712px;
    left: 0px;
    top: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #A4C2B2 100%);
}

.Person-image {
    position: absolute;
    width: 537px;
    height: 592px;
    left: 903px;
    top: 120px;
}

.Banner-title {
    position: absolute;
    width: 573px;
    height: 92px;
    left: 155px;
    top: 209px;
    font-family: 'Castoro';
    font-style: normal;
    font-weight: 400;
    font-size: 48vw;
    line-height: 46px;
    color: #000000;
}

.Banner-subtitle {
    position: absolute;
    width: 527px;
    height: 56px;
    left: 155px;
    top: 321px;
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
}

.Logo {
    position: absolute;
    left: 60px;
    top: 10px;
}

.input-email:focus {
    border-color: #333333ff !important;
}

/*.button-discovery+:hover {
    width:"30%";
    height:"1.8vw";
}*/